import { Button, Form, FormItem, Input } from 'element-ui';
import user from '../js/user';
import  {login} from '../api/index'
import { showErrorTip } from '../util';
export default {
  name:'login',
  components: {
    elButton: Button,
    elForm: Form,
    elFormItem: FormItem,
    elInput: Input,
  },
  data(){
    return {
      ruleForm:{
        username:"",
        password:'',
      },
      rules:{
        username:[
          {
            required: true, message: '请输入用户名', trigger: 'blur'
          }
        ],
        password:[
          {
            required: true, message: '请输入密码', trigger: 'blur'
          }
        ],
      },
    }
  },
  mounted(){
    this.getUser();
  },
  methods:{
    async getUser(){
      await user.getUser();
      if(user.isLogin === true){
        this.$router.push('/');
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {username,password}  = this.ruleForm;
          const res = await login({
            username,
            password
            // "username": "18500323169", 
            // "password": "EEvO1q8UEwQ"
          })
          if(res.error === null){
            await this.getUser();
            if(user.isLogin === true && user.hasAuth === false){
              showErrorTip('您尚未开通系统权限不能查看');
            }
          }
        } else {
          return false;
        }
      });
    },
    onRegister(){
      window.open('http://www.aisichuang.com/ascts/intentionController/toRegister.do');
    }
  }
}